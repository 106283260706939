import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function About({ data }){
    return(
        <Layout>
            <SEO title="About our site" />
        <h1>About {data.site.siteMetadata.title}</h1>
        <div style={{ marginBottom: `1.45rem` }}>
            <p>We're just a basic site, basically.</p>
            <p>You can find out everything about the best staycation activites and destinations.</p>
            <p>So, pretty useful actually.</p>
        </div>
        </Layout>
    )
}

export const query = graphql`
query {
    site {
        siteMetadata{
            title
        }
    }
}
`